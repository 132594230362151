import { mediaQuery, scrollTo } from './utils/functions.js';

mediaQuery();


function requireAll(r){
  r.keys().forEach(r);
}


const resizeHandler = (e)=>{
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

window.addEventListener('load', resizeHandler);
window.addEventListener('resize', resizeHandler);

//check if the device is apple's product
const isApple = /Mac|iPod|iPhone|iPad/.test(navigator.platform);
if(!isApple){
  document.body.classList.add('no-apple');
}


const pageScrollTo = (tar, duration, e) => {
  tar = tar.replace('#', '');

  const targetElement = document.querySelector(`[data-id="${tar}"]`);

  if (targetElement || tar === 'header') {

    if (e) {
      e.preventDefault();
    }

    let diff = window.matchMedia('(max-width: 900px)').matches ? 64 : 64;

    let pos = tar === 'header' ? 0 : targetElement.getBoundingClientRect().top + window.scrollY - diff;

    // let speed = duration ? duration : 500;

    // Smooth scroll to the position
    scrollTo({
      to: pos
    });

    const navElements = document.querySelectorAll('.mobile-modal-site-nav, .modal-site-nav');
    navElements.forEach(nav => {
      if (nav.classList.contains('visible')) {
        nav.classList.remove('visible');
      }
    });
  }
};


document.querySelectorAll('.anchor, a').forEach(function(element) {
  element.addEventListener('click', function(e) {
    let href = e.currentTarget.getAttribute('href');
    //href = href.replace(window.theme.site_url, '').replace(window.theme.current_path, '');

    let tgt = e.currentTarget.getAttribute('href').split('#');
    if (tgt[1] && href === '#' + tgt[1]) {
      if (document.querySelector(`[data-id="${tgt[1]}"]`) || tgt[1] === 'header') {
        pageScrollTo(tgt[1], 500, e);
      }
    }
  });
});


let ratio = 0.8;
const prlxElems = document.querySelectorAll('.prlx, .prlx2');
const indicator = document.querySelector('.hero-section .indicator');
const heroContent = document.querySelector('.hero-section .section__content');
const logo = document.querySelector('.site-header .logo');

window.addEventListener('scroll', (e) => {

  const st = window.scrollY;

  if(st > 0){
    //select body
    document.body.classList.add('scrolled');
  } else {
    document.body.classList.remove('scrolled');
  }

  // console.log(Math.max(52, 100 - st * 0.5))
  // logo.style.width = `${Math.min(52, 52 - st)}%`;


  prlxElems.forEach((elem, index) => {

    const thisY = elem.getBoundingClientRect().top + st;

    if(elem.classList.contains('prlx2')){
      ratio = 1;
      elem.style.backgroundPositionY = `${-((st - thisY) * 0.1)}px`;
      elem.classList.add('executed');
    } else {
      ratio = 0.8;
      if(thisY <= st + window.innerHeight * ratio || st + window.innerHeight >= document.body.offsetHeight - 100){
        elem.classList.add('executed');
      }
      // check if reached the bottom of the page
      // if(thisY + elem.offsetHeight >= st){
      //   elem.style.transform = `translateY(${(st - thisY) * 0.3}px)`;
      // }
    }

  });

});

//triger scroll
window.dispatchEvent(new Event('scroll'));

const timer = (func, time) => {
  setTimeout(() => {
    func();
  }, time);
}

// timer(() => {
//   heroContent.classList.add('visible');
// }, 1000);

// timer(() => {
//   indicator.classList.add('visible');
// }, 1500);






